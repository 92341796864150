import axios from "axios";
import toast from "react-hot-toast";
import localforage from "localforage";

import {
  AppResponse,
  AppUser,
  Dashboard,
  Technician,
  Contact,
  Project,
  Inspection,
  Installation,
  Place,
  PagesInfo,
  MaintenanceProject,
  Maintenance,
  SalesRepresentative,
  CRMLead,
  CRMActivity,
  CRMSource,
  CRMGroup,
  CRMConversation,
  CRMMessage,
  LeadProjectInfo,
  Notification,
  LeadFinancialDocument,
  OtherProject,
  OtherProjectInspection,
  OtherProjectInstallation,
} from "./models";

import { downloadBlob } from "./misc";
import { appResponseActions } from "./constants";

const RequestTypes = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// const serverURL = "http://localhost:4001";
const serverURL = "https://ultrasolar-dev-server.umarbinayaz.com";
// const serverURL = "https://server.ultrasolar.app";

async function login({ email, password }) {
  try {
    const url = `${serverURL}/auth/admins/login`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { email, password },
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function sync(appUser) {
  try {
    const url = `${serverURL}/auth/sync`;

    const headers = await _headers();
    headers["authorization"] = `Bearer ${appUser.token}`;

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
    });

    if (res.success) {
      let appUser = AppUser.parse(res.data);
      return appUser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readDashboard() {
  try {
    const url = `${serverURL}/dashboard/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        stats: "year",
      },
    });

    if (res.success) {
      const dashboard = Dashboard.parse(res.data);
      return dashboard;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createTechnician({
  first_name,
  last_name,
  email,
  phone,
  work_area,
}) {
  try {
    const url = `${serverURL}/technicians/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { first_name, last_name, email, phone, work_area },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readTechnicians({
  page_no,
  sort_field,
  sort_type,
  name,
  is_archived,
}) {
  try {
    const url = `${serverURL}/technicians/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, sort_field, sort_type, name, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const technicians = res.data.array.map((_) => Technician.parse(_));

      return [pagesInfo, technicians];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readTechnician({ id }) {
  try {
    const url = `${serverURL}/technicians/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const technician = Technician.parse(res.data);

        return technician;
      }

      return null;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateTechnician({
  id,
  first_name,
  last_name,
  phone,
  is_active,
  is_archived,
}) {
  try {
    const url = `${serverURL}/technicians/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: { id, first_name, last_name, phone, is_active, is_archived },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createContact({
  acquired_at,
  first_name,
  last_name,
  email,
  phone,
  address,
  source_id,
  type,
  entity_type,
  status,
  sales_representative_id,
}) {
  try {
    const url = `${serverURL}/contacts/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        acquired_at,
        first_name,
        last_name,
        email,
        phone,
        address,
        source_id,
        type,
        entity_type,
        status,
        sales_representative_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readContacts({
  page_no,
  sort_field,
  sort_type,
  group_id,
  type,
  name,
  email,
  phone,
  source_id,
  is_finance_approved,
  status,
  acquired_at_start,
  acquired_at_end,
  notification_id,
  has_projects,
  is_archived,
}) {
  try {
    const url = `${serverURL}/contacts/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        sort_field,
        sort_type,
        group_id,
        type,
        name,
        email,
        phone,
        source_id,
        is_finance_approved,
        status,
        acquired_at_start,
        acquired_at_end,
        notification_id,
        has_projects,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const contacts = res.data.array.map((_) => Contact.parse(_));

      return [pagesInfo, contacts];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readContact({ id }) {
  try {
    const url = `${serverURL}/contacts/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const contact = Contact.parse(res.data);

        return contact;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateContact({
  id,
  type,
  acquired_at,
  first_name,
  last_name,
  email,
  phone,
  website,
  address,
  notes,
  source_id,
  entity_type,
  sales_representative_id,
  status,
  is_finance_approved,
  is_archived,
}) {
  try {
    const url = `${serverURL}/contacts/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        type,
        acquired_at,
        first_name,
        last_name,
        email,
        phone,
        address,
        website,
        notes,
        source_id,
        entity_type,
        sales_representative_id,
        status,
        is_finance_approved,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function notifySalesReps({ array }) {
  try {
    const url = `${serverURL}/contacts/notify_sales_reps`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        array,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createProject({
  client_id,
  address,
  power_kit,
  title,
  description,
  installation_kit,
  estimate,
  inspection,
  installation,
  inspection_technician_id,
  installation_technician_id,
}) {
  try {
    const url = `${serverURL}/projects/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        client_id,
        address,
        power_kit,
        title,
        description,
        installation_kit,
        estimate,
        inspection,
        installation,
        inspection_technician_id,
        installation_technician_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readProjects({
  page_no,
  sort_field,
  sort_type,
  title,
  client_id,
  inspection_technician_id,
  installation_technician_id,
  notification_id,
  status,
  is_archived,
}) {
  try {
    const url = `${serverURL}/projects/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        sort_field,
        sort_type,
        title,
        client_id,
        inspection_technician_id,
        installation_technician_id,
        notification_id,
        status,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const projects = res.data.array.map((_) => Project.parse(_));

      return [pagesInfo, projects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateProject({
  id,
  client_id,
  address,
  power_kit,
  title,
  description,
  installation_kit,
  estimate,
  inspection,
  installation,
  inspection_technician_id,
  installation_technician_id,
  is_archived,
}) {
  try {
    const url = `${serverURL}/projects/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        client_id,
        address,
        power_kit,
        title,
        description,
        installation_kit,
        estimate,
        inspection,
        installation,
        inspection_technician_id,
        installation_technician_id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readInspection({ project_id }) {
  try {
    const url = `${serverURL}/inspections/read/${project_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const inspection = Inspection.parse(res.data);
      return inspection;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function generatePDFInspection({ id, name }) {
  try {
    const url = `${serverURL}/inspections/generate_pdf/${id}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      downloadBlob({ blob: data, name: `${name}.pdf` });
    }
  } catch (e) {
    return null;
  }
}

async function readInstallation({ project_id }) {
  try {
    const url = `${serverURL}/installations/read/${project_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const inspection = Installation.parse(res.data);
      return inspection;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readPlaces({ query, restrict = false }) {
  try {
    const url = `${serverURL}/places/query`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        query,
        restrict,
      },
    });

    if (res.success) {
      const places = res.data.map((_) => Place.parse(_));
      return places;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function generatePDFInstallation({ id, name }) {
  try {
    const url = `${serverURL}/installations/generate_pdf/${id}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      downloadBlob({ blob: data, name: `${name}.pdf` });
    }
  } catch (e) {
    return null;
  }
}

async function downloadFile({ file }) {
  try {
    const url = `${serverURL}/files/download?url=${file}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      var index = file.lastIndexOf("/") + 1;
      var name = file.substr(index);

      downloadBlob({ blob: data, name: name });
    }
  } catch (e) {
    return null;
  }
}

async function resetPassword({ old_password, new_password }) {
  try {
    const url = `${serverURL}/auth/admins/reset_password`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { old_password, new_password },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword1() {
  try {
    const url = `${serverURL}/auth/admins/forgot_password_1`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword2({ code }) {
  try {
    const url = `${serverURL}/auth/admins/forgot_password_2`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        code,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function forgotPassword3({ password }) {
  try {
    const url = `${serverURL}/auth/admins/forgot_password_3`;

    const headers = await _headers({ parseAppUser: false });

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        password,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createMaintenanceProject({
  client_id,
  address,
  title,
  description,
  maintenance,
  technician_id,
}) {
  try {
    const url = `${serverURL}/maintenance_projects/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        client_id,
        address,
        title,
        description,
        maintenance,
        technician_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readMaintenanceProjects({
  page_no,
  title,
  client_id,
  technician_id,
  status,
  is_archived,
}) {
  try {
    const url = `${serverURL}/maintenance_projects/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        title,
        client_id,
        technician_id,
        status,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const maintenanceProjects = res.data.array.map((_) =>
        MaintenanceProject.parse(_)
      );

      return [pagesInfo, maintenanceProjects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateMaintenanceProject({
  id,
  client_id,
  address,
  title,
  description,
  maintenance,
  technician_id,
  is_archived,
}) {
  try {
    const url = `${serverURL}/maintenance_projects/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        client_id,
        address,
        title,
        description,
        maintenance,
        technician_id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readMaintenance({ maintenance_project_id }) {
  try {
    const url = `${serverURL}/maintenances/read/${maintenance_project_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const maintenance = Maintenance.parse(res.data);
      return maintenance;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function generatePDFMaintenance({ id, name }) {
  try {
    const url = `${serverURL}/maintenances/generate_pdf/${id}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      downloadBlob({ blob: data, name: `${name}.pdf` });
    }
  } catch (e) {
    return null;
  }
}

async function createSalesRepresentative({
  first_name,
  last_name,
  email,
  phone,
  coverage_area,
}) {
  try {
    const url = `${serverURL}/sales_representatives/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { first_name, last_name, email, phone, coverage_area },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readSalesRepresentatives({
  page_no,
  sort_field,
  sort_type,
  name,
  is_archived,
}) {
  try {
    const url = `${serverURL}/sales_representatives/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, sort_field, sort_type, name, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const salesRepresentatives = res.data.array.map((_) =>
        SalesRepresentative.parse(_)
      );

      return [pagesInfo, salesRepresentatives];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readSalesRepresentative({ id }) {
  try {
    const url = `${serverURL}/sales_representatives/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const salesRepresentative = SalesRepresentative.parse(res.data);

        return salesRepresentative;
      }

      return null;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateSalesRepresentative({
  id,
  first_name,
  last_name,
  phone,
  coverage_area,
  is_active,
  is_archived,
}) {
  try {
    const url = `${serverURL}/sales_representatives/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        first_name,
        last_name,
        phone,
        coverage_area,
        is_active,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMLead({
  title,
  customer_first_name,
  customer_last_name,
  customer_email,
  customer_phone,
  customer_address,
  source,
  sales_representative_id,
}) {
  try {
    const url = `${serverURL}/crm_leads/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_address,
        source,
        sales_representative_id,
      },
    });

    if (res.success) {
      return CRMLead.parse(res.data);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMLeads({ page_no, title, is_archived }) {
  try {
    const url = `${serverURL}/crm_leads/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, page_size: 50, title, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmLeads = res.data.array.map((_) => CRMLead.parse(_));

      return [pagesInfo, crmLeads];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMLead({ id }) {
  try {
    const url = `${serverURL}/crm_leads/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const crmLead = CRMLead.parse(res.data);

        return crmLead;
      }

      return null;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateCRMLead({
  id,
  title,
  customer_first_name,
  customer_last_name,
  customer_email,
  customer_phone,
  customer_address,
  sales_representative_id,
  status,
  level,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_leads/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        title,
        customer_first_name,
        customer_last_name,
        customer_email,
        customer_phone,
        customer_address,
        sales_representative_id,
        status,
        level,
        is_archived,
      },
    });

    if (res.success) {
      return CRMLead.parse(res.data);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMActivity({ contact_id, type, date, note, attachment }) {
  try {
    const url = `${serverURL}/crm_activities/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        contact_id,
        type,
        date,
        note,
        attachment,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMActivities({
  page_no,
  contact_id,
  crm_deal_id,
  has_attachment,
}) {
  try {
    const url = `${serverURL}/crm_activities/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, contact_id, crm_deal_id, has_attachment },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmActivities = res.data.array.map((_) => CRMActivity.parse(_));

      return [pagesInfo, crmActivities];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMSource({ title, description, icon }) {
  try {
    const url = `${serverURL}/crm_sources/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        description,
        icon,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMSources({
  page_no,
  sort_field,
  sort_type,
  title,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_sources/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, sort_field, sort_type, title, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmSources = res.data.array.map((_) => CRMSource.parse(_));

      return [pagesInfo, crmSources];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateCRMSource({ id, title, description, icon, is_archived }) {
  try {
    const url = `${serverURL}/crm_sources/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        title,
        description,
        icon,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMGroup({
  title,
  description,
  contacts_type,
  contacts_source_id,
  contacts_status,
  contacts_project_status,
  contacts_acquired_at_start,
  contacts_acquired_at_end,
  contacts_is_archived,
}) {
  try {
    const url = `${serverURL}/crm_groups/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        title,
        description,
        contacts_type,
        contacts_source_id,
        contacts_status,
        contacts_project_status,
        contacts_acquired_at_start,
        contacts_acquired_at_end,
        contacts_is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMGroups({
  page_no,
  sort_field,
  sort_type,
  title,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_groups/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, sort_field, sort_type, title, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmGroups = res.data.array.map((_) => CRMGroup.parse(_));

      return [pagesInfo, crmGroups];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMGroup({ id }) {
  try {
    const url = `${serverURL}/crm_groups/read/${id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const crmGroup = CRMGroup.parse(res.data);

      return crmGroup;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateCRMGroup({
  id,
  title,
  description,
  contacts_type,
  contacts_source_id,
  contacts_status,
  contacts_project_status,
  contacts_acquired_at_start,
  contacts_acquired_at_end,
  contacts_is_archived,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_groups/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        title,
        description,
        contacts_type,
        contacts_source_id,
        contacts_status,
        contacts_project_status,
        contacts_acquired_at_start,
        contacts_acquired_at_end,
        contacts_is_archived,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function uploadFile({ name, file }) {
  try {
    const url = `${serverURL}/files/upload`;

    const headers = await _headers();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", file);

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: formData,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMConversation({ contact_id, group_id }) {
  try {
    const url = `${serverURL}/crm_conversations/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        contact_id,
        group_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMConversations({
  page_no,
  title,
  contact_id,
  group_id,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_conversations/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { page_no, title, contact_id, group_id, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmConversations = res.data.array.map((_) =>
        CRMConversation.parse(_)
      );

      return [pagesInfo, crmConversations];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createCRMMessage({
  conversation_id,
  type,
  text,
  attachment,
  additional,
}) {
  try {
    const url = `${serverURL}/crm_messages/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        conversation_id,
        type,
        text,
        attachment,
        additional,
      },
    });

    if (res.success) {
      return CRMMessage.parse(res.data);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readCRMMessages({ conversation_id, page_no, is_archived }) {
  try {
    const url = `${serverURL}/crm_messages/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: { conversation_id, page_no, is_archived },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const crmMessages = res.data.array.map((_) => CRMMessage.parse(_));

      return [pagesInfo, crmMessages];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readHasUnreadMessages() {
  try {
    const url = `${serverURL}/crm_conversations/has_unread_messages`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readLeadProjectInfo({ lead_id }) {
  try {
    const url = `${serverURL}/lead_project_infos/read/${lead_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      if (res.data) {
        const leadProjectInfo = LeadProjectInfo.parse(res.data);

        return leadProjectInfo;
      }

      return null;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readGroupContacts({
  id,
  page_no,
  sort_field,
  sort_type,
  name,
  email,
  phone,
  source_id,
  is_archived,
}) {
  try {
    const url = `${serverURL}/crm_groups/read_contacts`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        id,
        page_no,
        sort_field,
        sort_type,
        name,
        email,
        phone,
        source_id,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const contacts = res.data.array.map((_) => Contact.parse(_));

      return [pagesInfo, contacts];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readNotifications({ page_no, is_archived }) {
  try {
    const url = `${serverURL}/notifications/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const contacts = res.data.array.map((_) => Notification.parse(_));

      return [pagesInfo, contacts];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateNotification({ id, is_archived }) {
  try {
    const url = `${serverURL}/notifications/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readClientProjectsCount({ client_id }) {
  try {
    const url = `${serverURL}/projects/read_client_projects_count`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        client_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readLeadFinancialDocuments({ lead_id }) {
  try {
    const url = `${serverURL}/lead_finance_documents/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        lead_id,
      },
    });

    if (res.success) {
      const leadFinancialDocuments = res.data.map((_) =>
        LeadFinancialDocument.parse(_)
      );

      return leadFinancialDocuments;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
}

async function updateLeadFinancialDocument({ lead_id, type, status }) {
  try {
    const url = `${serverURL}/lead_finance_documents/update_status`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        lead_id,
        type,
        status,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function createOtherProject({
  client_id,
  address,
  power_kit,
  title,
  description,
  installation_kit,
  estimate,
  inspection,
  installation,
  inspection_technician_id,
  installation_technician_id,
}) {
  try {
    const url = `${serverURL}/other_projects/create`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        client_id,
        address,
        power_kit,
        title,
        description,
        installation_kit,
        estimate,
        inspection,
        installation,
        inspection_technician_id,
        installation_technician_id,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjects({
  page_no,
  sort_field,
  sort_type,
  title,
  client_id,
  inspection_technician_id,
  installation_technician_id,
  status,
  is_archived,
}) {
  try {
    const url = `${serverURL}/other_projects/read`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.post,
      url,
      headers: headers,
      body: {
        page_no,
        sort_field,
        sort_type,
        title,
        client_id,
        inspection_technician_id,
        installation_technician_id,
        status,
        is_archived,
      },
    });

    if (res.success) {
      const pagesInfo = PagesInfo.parse(res.data.pages_info);
      const otherProjects = res.data.array.map((_) => OtherProject.parse(_));

      return [pagesInfo, otherProjects];
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function updateOtherProject({
  id,
  client_id,
  address,
  power_kit,
  title,
  description,
  installation_kit,
  estimate,
  inspection,
  installation,
  inspection_technician_id,
  installation_technician_id,
  is_archived,
}) {
  try {
    const url = `${serverURL}/other_projects/update`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.put,
      url,
      headers: headers,
      body: {
        id,
        client_id,
        address,
        power_kit,
        title,
        description,
        installation_kit,
        estimate,
        inspection,
        installation,
        inspection_technician_id,
        installation_technician_id,
        is_archived,
      },
    });

    if (res.success) {
      return res.data;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjectInspection({ other_project_id }) {
  try {
    const url = `${serverURL}/other_project_inspections/read/${other_project_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const otherProjectInspections = OtherProjectInspection.parse(res.data);
      return otherProjectInspections;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function generatePDFOtherProjectInspection({ id, name }) {
  try {
    const url = `${serverURL}/other_project_inspections/generate_pdf/${id}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      downloadBlob({ blob: data, name: `${name}.pdf` });
    }
  } catch (e) {
    return null;
  }
}

async function readOtherProjectInstallation({ other_project_id }) {
  try {
    const url = `${serverURL}/other_project_installations/read/${other_project_id}`;

    const headers = await _headers();

    const res = await _request({
      requestType: RequestTypes.get,
      url,
      headers: headers,
    });

    if (res.success) {
      const otherProjectInstallation = OtherProjectInstallation.parse(res.data);
      return otherProjectInstallation;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

async function generatePDFOtherProjectInstallation({ id, name }) {
  try {
    const url = `${serverURL}/other_project_installations/generate_pdf/${id}`;

    const headers = await _headers();

    const res = await axios({
      method: RequestTypes.get,
      url: url,
      headers: headers,
      responseType: "blob",
    });

    const data = res.data;

    if (data) {
      downloadBlob({ blob: data, name: `${name}.pdf` });
    }
  } catch (e) {
    return null;
  }
}

async function _request({ requestType, url, headers, body, orotund = true }) {
  let appResponse;

  try {
    const res = await axios({
      method: requestType,
      url: url,
      data: body,
      headers: headers,
    });

    const data = res.data;

    appResponse = AppResponse.parse(data);

    if (appResponse.action == appResponseActions.logout) {
      localStorage.removeItem("AppStorage/appUser");
      window.location = "/";
      toast.error("Session expired. Login again.");
    }
  } catch (e) {
    appResponse = AppResponse.failure();
  }

  if (!appResponse.success && orotund) {
    toast.error(appResponse.errorMessage);
  }

  return appResponse;
}

async function _headers(props = { parseAppUser: true }) {
  const obj = {};

  obj["x-access-token"] = `Basic ${process.env.REACT_APP_X_ACCESS_TOKEN}`;

  if (props.parseAppUser) {
    const appUser = await localforage.getItem("appUser");

    if (appUser != null) {
      obj["authorization"] = `Bearer ${appUser.token}`;
    }
  }

  return obj;
}

export {
  login,
  sync,
  readDashboard,
  createTechnician,
  readTechnicians,
  readTechnician,
  updateTechnician,
  createContact,
  readContacts,
  readContact,
  updateContact,
  notifySalesReps,
  createProject,
  readProjects,
  updateProject,
  readInspection,
  generatePDFInspection,
  readInstallation,
  generatePDFInstallation,
  readPlaces,
  downloadFile,
  resetPassword,
  forgotPassword1,
  forgotPassword2,
  forgotPassword3,
  createMaintenanceProject,
  readMaintenanceProjects,
  updateMaintenanceProject,
  readMaintenance,
  generatePDFMaintenance,
  createSalesRepresentative,
  readSalesRepresentatives,
  readSalesRepresentative,
  updateSalesRepresentative,
  createCRMLead,
  readCRMLeads,
  readCRMLead,
  updateCRMLead,
  createCRMActivity,
  readCRMActivities,
  createCRMSource,
  readCRMSources,
  updateCRMSource,
  createCRMGroup,
  readCRMGroups,
  readCRMGroup,
  updateCRMGroup,
  createCRMConversation,
  readCRMConversations,
  createCRMMessage,
  readCRMMessages,
  readHasUnreadMessages,
  readLeadProjectInfo,
  readGroupContacts,
  readNotifications,
  updateNotification,
  readClientProjectsCount,
  readLeadFinancialDocuments,
  updateLeadFinancialDocument,
  createOtherProject,
  readOtherProjects,
  updateOtherProject,
  readOtherProjectInspection,
  generatePDFOtherProjectInspection,
  readOtherProjectInstallation,
  generatePDFOtherProjectInstallation,
  uploadFile,
};
